
import StepsProgressBar from "@/app/components/Stats/StepsProgressBar.vue";
import logService from "@/app/services/LogService";
import StatsTile from "@/app/pages/stats/StatsTile.vue";

export default {
  components: {StatsTile, StepsProgressBar},
  computed: {
    stepsRemaining() {
      return this.streak.minStepsPerDay - this.streak.stepsToday;
    },
    minDailySteps() {
      return this.streak.minStepsPerDay;
    },
    streak() {
      return logService.getReadingStreak();
    }
  }
}

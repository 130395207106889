
import MilestoneBadge from "@/app/components/Stats/MilestoneBadge.vue";
import PersistentStorage from "@/core/store/PersistentStorage";
import moment from "moment-with-locales-es6";
import authenticationService from "@/core/services/AuthenticationService";
import logService from "@/app/services/LogService";

export default {
  components: {MilestoneBadge},
  props: ['steps', 'name'],
  data() {
    return {
      milestones: [100, 200, 300, 400, 500, 600, 700, 800],
      showToolTip: false
    }
  },
  computed: {
    stepsTotal() {
      let total = 0;
      let log = [...logService.getCombinedReadingLog()];
      log.forEach(book => {
        (book.log_entries || []).forEach(entry => {
          total += entry.steps;
        });
      });

      return total;
    },
    stepsToday() {
      let today = moment().format('YYYY-MM-DD') + ' 00:00:00';
      let log = [...logService.getCombinedReadingLog()];
      return log.reduce((sum, book) => {
        return sum + (book.log_entries || []).reduce((entrySum, entry) => {
          return entrySum + ((entry.entry_date == today) ? entry.steps : 0);
        }, 0);
      }, 0);

    },
    stepsAwaitingApproval() {
      let stepsAwaitingApproval = 0
      let log = PersistentStorage.appData.log;
      log.map((index, item) => {
        let logEntries = index.log_entries
        logEntries.map((index, item) => {
          if (index.review_status === 'Submitted') {
            stepsAwaitingApproval += index.steps
          }
          return ''
        })
        return ''
      })
      return stepsAwaitingApproval
    },
    progress() {
      return {
        goldWidth: ((this.stepsTotal - this.stepsAwaitingApproval) / 900 * 100) + '%',
        shadedWidth: (this.stepsTotal / 900 * 100) + '%'
      }
    },
  },
  methods: {
    achieved(steps, milestone) {
      if (steps >= milestone) {
        return 'achieved'
      }
    }
  }
}

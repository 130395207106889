
import StepsProgressBar from "@/app/components/Stats/StepsProgressBar.vue";
import moment from 'moment-with-locales-es6';
import logService from "@/app/services/LogService";

export default {
  components: {StepsProgressBar},
  computed: {
    todaysDate() {
      let m = moment();
      m.locale(this.$i18n.locale);
      return m.format("dddd LL")
    },
    stepsToday() {
      let today = moment().format('YYYY-MM-DD') + ' 00:00:00';

      let log = logService.getCombinedReadingLog();

      return log.reduce((sum, book) => {
        return sum + (book.log_entries || []).reduce((entrySum, entry) => {
          return entrySum + ((entry.entry_date == today) ? entry.steps : 0);
        }, 0);
      }, 0);

    }
  }
}

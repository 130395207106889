
import StepsProgressBar from "@/app/components/Stats/StepsProgressBar.vue";
import bookService from "@/app/services/bookService";
import store from "@/core/store/PersistentStorage";


export default {
  components: {StepsProgressBar},
  computed: {
    currentSchoolYearName() {
      return store?.appData?.thisSchoolYear?.schoolYearName;
    },
    stepsAwaitingApproval() {
      return bookService.stepsAwaitingApproval();
    },
    totalSteps() {
        return bookService.stepsTotal();
    }
  }
}

import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "progress-row" }
const _hoisted_2 = { class: "progress-col-bar mt-1" }
const _hoisted_3 = { class: "progress" }
const _hoisted_4 = ["aria-valuenow", "aria-valuemax"]
const _hoisted_5 = { class: "progress-col-number" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", {
          class: "progress-bar",
          role: "progressbar",
          "aria-valuenow": $props.steps,
          "aria-valuemin": "0",
          "aria-valuemax": $props.max,
          style: _normalizeStyle({ 'width': $options.barWidth }),
          "aria-label": "Reading Steps Progress Bar"
        }, null, 12, _hoisted_4)
      ])
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("p", {
        style: _normalizeStyle({'color': $props.steps >= $props.max ? '#000' : '#4F4F4F', fontWeight: 'bold'})
      }, _toDisplayString($props.steps) + " / 4 ", 5)
    ])
  ]))
}
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, normalizeStyle as _normalizeStyle, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-a0dad300"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "card" }
const _hoisted_2 = { class: "card-header" }
const _hoisted_3 = { class: "steps-chart-card-header" }
const _hoisted_4 = { class: "time-period" }
const _hoisted_5 = { class: "toggle-period" }
const _hoisted_6 = { style: {"text-align":"right","fontWeight":"bold","fontSize":"1.25em"} }
const _hoisted_7 = { class: "card-body" }
const _hoisted_8 = { class: "axis-title x-axis-title" }
const _hoisted_9 = { class: "axis-title y-axis-title" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Bar = _resolveComponent("Bar")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.$t('pages.stats.stepsChart.header', {period: $options.chartHeader})), 1),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("button", {
            class: _normalizeClass($data.period === 'Month' ? 'active': ''),
            onClick: _cache[0] || (_cache[0] = ($event: any) => ($options.toggleTimePeriod('Month')))
          }, _toDisplayString(_ctx.$t('pages.stats.stepsChart.monthly')), 3),
          _createElementVNode("button", {
            class: _normalizeClass($data.period === 'Week' ? 'active': ''),
            onClick: _cache[1] || (_cache[1] = ($event: any) => ($options.toggleTimePeriod('Week')))
          }, _toDisplayString(_ctx.$t('pages.stats.stepsChart.weekly')), 3)
        ]),
        _createElementVNode("p", _hoisted_6, _toDisplayString($options.periodTitle), 1)
      ])
    ]),
    _createElementVNode("div", _hoisted_7, [
      _createVNode(_component_Bar, {
        "chart-options": $data.chartOptions,
        "chart-data": $options.chartData,
        "dataset-id-key": 'label',
        style: _normalizeStyle($options.chartStyles)
      }, null, 8, ["chart-options", "chart-data", "style"]),
      _createElementVNode("p", _hoisted_8, _toDisplayString(_ctx.$t('pages.stats.stepsChart.day')), 1),
      _createElementVNode("p", _hoisted_9, _toDisplayString(_ctx.$t('pages.stats.stepsChart.numberOfSteps')), 1)
    ])
  ]))
}
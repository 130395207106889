
import store from "@/core/store/PersistentStorage";
import moment from "moment-with-locales-es6";
import logService from "@/app/services/LogService";
import apiClient from "@/core/services/apiClient";
import authenticationService from "@/core/services/AuthenticationService";


export default {
  computed: {
    schoolYearName() {
      if (logService.schoolYearData == null || logService.schoolYearData.length < 1) return '';
      return this.$t('pages.stats.stepCount.since') + logService.schoolYearData[0].schoolYearName || '';
    },
    averages() {
      const date = new Date();
      let startOfWeek = moment().startOf('week').locale(this.$i18n.locale).format('MMMM DD');
      let endOfWeek = moment().endOf('week').locale(this.$i18n.locale).format('MMMM DD')
      let averages = [
        {
          name: this.$t('pages.stats.stepCount.weekly'),
          time: startOfWeek + ' - ' + endOfWeek,
          value: this.displaySteps().week
        },
        {
          name: this.$t('pages.stats.stepCount.monthly'),
          time: moment().locale(this.$i18n.locale).format('MMMM'),
          value: this.displaySteps().month
        }
        ];

      // Only include the step counts that depend on school year if we are logged in as a
      // student - otherwise there will be no school year.
      if (authenticationService.currentUser().studentId > 0) {
        averages = [...averages,
        {
          name: this.$t('pages.stats.stepCount.yearly'),
          time: date.getFullYear(),
          value: this.displaySteps().year
        },
        {
          name: this.$t('pages.stats.stepCount.lifetime'),
          time: this.schoolYearName,
          value: this.displaySteps().allTime
        },
      ];
      }
      return averages;
    }
  },
  methods: {
    /* Accepts time in minutes */
    calculateTime(i) {
      let time = i * 15;
      if (time < 59) {
        return time + ' min.'
      } else if (time >= 59) {
        let hours = (time / 60).toString();
        return parseFloat(hours).toFixed(1) + ' hrs.'
      }
    },
    displaySteps() {
      let date = new Date()
      date.setDate(date.getDate() - 7)
      let week = moment().startOf('week').format('YYYY-MM-DD') + ' 00:00:00';
      let month = moment().format('YYYY-MM-01') + ' 00:00:00';
      let year = moment().format('YYYY-01-01') + ' 00:00:00';
      let allTime = moment().format('0000-01-01') + ' 00:00:00';
      return {
        week: this.getSteps(week),
        month: this.getSteps(month),
        year: this.getSteps(year),
        allTime: this.getSteps(allTime)
      }
    },
    getSteps(timeFrame) {
      let today = moment().format('YYYY-MM-DD') + ' 00:00:00';

      // Calculate based on combined SP + ARC Reads data.
      return logService.getCombinedReadingLog().reduce((sum, book) => {
        return sum + (book.log_entries || []).reduce((entrySum, entry) => {
          return entrySum + ((entry.entry_date <= today && entry.entry_date >= timeFrame) ? entry.steps : 0);
        }, 0);
      }, 0);
    }
  }
}

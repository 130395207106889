
import {setCurrentPageTitle} from "@/core/helpers/breadcrumb";
import authenticationService from "@/core/services/AuthenticationService";
import MilestonesLarge from "@/app/pages/stats/MilestonesLarge.vue";
import TodaysSteps from "@/app/pages/stats/TodaysSteps.vue";
import ReadingStreak from "@/app/pages/stats/ReadingStreak.vue";
import StepCount from "@/app/pages/stats/StepCount.vue";
import GenresRead from "@/app/pages/stats/GenresRead.vue";
import StepsChart from "@/app/pages/stats/StepsChart.vue";
import StatsPanel from "@/app/components/Stats/StatsPanel.vue";
import StepBreakdown from "@/app/pages/stats/StepBreakdown.vue";

export default {
  components: {GenresRead, StepCount, ReadingStreak, TodaysSteps, MilestonesLarge, StepsChart, StepBreakdown},
  mounted() {
    setCurrentPageTitle("My Stats");
  },
  computed: {
    getName() {
      return authenticationService.currentUser().profile.display_name;
    },
  }
}

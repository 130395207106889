import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "page-stats" }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "col-12" }
const _hoisted_4 = { class: "row" }
const _hoisted_5 = { class: "col-12 col-lg-4" }
const _hoisted_6 = { class: "col-12 col-lg-8" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_milestones_large = _resolveComponent("milestones-large")!
  const _component_step_breakdown = _resolveComponent("step-breakdown")!
  const _component_todays_steps = _resolveComponent("todays-steps")!
  const _component_reading_streak = _resolveComponent("reading-streak")!
  const _component_step_count = _resolveComponent("step-count")!
  const _component_genres_read = _resolveComponent("genres-read")!
  const _component_steps_chart = _resolveComponent("steps-chart")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_milestones_large, { name: $options.getName }, null, 8, ["name"])
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_step_breakdown),
        _createVNode(_component_todays_steps),
        _createVNode(_component_reading_streak),
        _createVNode(_component_step_count)
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createVNode(_component_genres_read, { class: "genre-chart-wrapper" }),
        _createVNode(_component_steps_chart)
      ])
    ])
  ]))
}
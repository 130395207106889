import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "card" }
const _hoisted_2 = { class: "card-header" }
const _hoisted_3 = { class: "step-count-card-header" }
const _hoisted_4 = { class: "card-body average-group-wrapper" }
const _hoisted_5 = { class: "average-data" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.$t('pages.stats.stepCount.header')), 1),
      _createTextVNode(" " + _toDisplayString(_ctx.$t('pages.stats.stepCount.conversion')), 1)
    ]),
    _createElementVNode("div", _hoisted_4, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.averages, (average) => {
        return (_openBlock(), _createElementBlock("div", {
          key: average,
          class: "average-group m-3"
        }, [
          _createElementVNode("p", null, _toDisplayString(average.name) + " | " + _toDisplayString(average.time), 1),
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("p", null, [
              _createElementVNode("b", null, _toDisplayString(average.value), 1),
              _createTextVNode(" " + _toDisplayString(_ctx.$t('pages.stats.stepCount.steps')) + " = ", 1),
              _createElementVNode("b", null, _toDisplayString($options.calculateTime(average.value)), 1)
            ])
          ])
        ]))
      }), 128))
    ])
  ]))
}
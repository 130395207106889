import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "card stats-page" }
const _hoisted_2 = { class: "card-header" }
const _hoisted_3 = { class: "genres-read-card-header" }
const _hoisted_4 = { class: "card-body" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_genre_chart = _resolveComponent("genre-chart")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.$t('pages.stats.genresRead.header')), 1)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_genre_chart, {
        class: "genre-chart",
        statsPage: true
      })
    ])
  ]))
}
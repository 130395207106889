
import {Bar} from 'vue-chartjs'
import {Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale} from 'chart.js'
import store from "@/core/store/PersistentStorage";
import moment from "moment-with-locales-es6";
import logService from "@/app/services/LogService";

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale);

const chartOptions = {
  responsive: true,
  plugins: {
    legend: {
      display: false
    },
  },
  scales: {
    x: {
      grid: {
        display: false
      },
    },
    y: {
      grid: {
        display: false
      }
    }
  }
};

export default {
  components: {Bar},
  computed: {
    chartData() {
      let chartData: any = {
        labels: [],
        datasets: [{
          backgroundColor: ['#007BEC'],
          borderWidth: 1,
          borderColor: ['transparent'],
          data: [],
        }]
      };

      // Flatten log entries into a simple array
      let logArray: any = [].concat(...logService.getCombinedReadingLog().map(({log_entries}) => log_entries || []));

      // Build daily totals.
      let periodStart = moment().startOf(this.period);
      let periodEnd = moment().endOf(this.period);
      let daysInPeriod = moment().daysInMonth(this.period);
      chartData.labels = [];
      chartData.datasets[0].data = [];
      for (let today = moment().startOf(this.period); daysInPeriod > 0; today.add('1', 'day')) {
        chartData.labels.push(today.date());
        chartData.datasets[0].data.push(0);
        daysInPeriod--;
      }

      // Fill in the data
      for (const entry of logArray) {
        let entryMoment = moment(entry.entry_date);
        if (entryMoment.isBetween(periodStart, periodEnd, 'days', '[]')) {
          let index = entryMoment.diff(periodStart, 'days');
          chartData.datasets[0].data[index] += entry.steps;
        }
      }

      return chartData;

    },
    chartHeader() {
      if (this.period === 'Month') {
        return this.$t('pages.stats.stepsChart.month')
      } else {
        return this.$t('pages.stats.stepsChart.week')
      }
    },
    chartStyles() {
      return {
        height: '100%',
        position: 'relative',
        width: '100%'
      }
    },
    periodTitle() {
      if (this.period == 'Month') {
        return moment().locale(this.$i18n.locale).format('MMMM YYYY');
      } else {
        let startOfWeek = moment().startOf('week').locale(this.$i18n.locale).format('MMMM DD');
        let endOfWeek = moment().endOf('week').locale(this.$i18n.locale).format('MMMM DD');
        return startOfWeek + ' - ' + endOfWeek
      }
    },
    stepsToday() {
      let today = moment().format('YYYY-MM-DD') + ' 00:00:00';

      return logService.getCombinedReadingLog().reduce((sum, book) => {
        return sum + (book.log_entries || []).reduce((entrySum, entry) => {
          return entrySum + ((entry.entry_date == today) ? entry.steps : 0);
        }, 0);
      }, 0);

    }
  },
  data() {
    return {
      period: 'Month',
      chartOptions
    }
  },
  methods: {
    toggleTimePeriod(i) {
      this.period = i
    }
  }
}
